.tracking-banner {
  background-image: url('./trackbanner.jpg'); 
  background-position: center;
  background-size: cover;
  padding: 120px 0;
  color: #fff;
  position: relative;
}

/* .tracking-banner:before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
} */

.tracking-banner h1 {
  font-size: 50px;
  position: relative;
  z-index: 2;
}

.text-primary {
  color: var(--primary) !important;
}

.form-control {
  font-size: 14px;
  width: 100%; 
  margin-bottom: 10px; 
}


.form-control:focus {
  box-shadow: none;
  outline: none;
  border-color: var(--primary);
}


.tracking-wrapper {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 20px; 
}

.home-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; 
}

.service-card {
  text-align: center;
  margin: 5px;
  
}

.service-card p{
  opacity: 0.8;
  font-weight: bold;
  
}
.service-card:hover {
  transform: scale(1.05); 
}

.circle-border {
  border: 1px solid #c0c0c0; 
  border-radius: 50%;
  width: 220px; 
  height: 220px; 
  overflow: hidden;
}

.circle-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  
}

.circle-logo {
  width: 50%; 
  height: auto;
 
}

.service-name {
  margin-top: 5px; 
  font-size: 14px; 
  color:black;
  text-decoration: none;
}

.service-name:hover,
.service-name:active,
.service-name:visited {
text-decoration: none;
}

.services-section-title{
text-align: center;
font-size: 30px;
font-weight: bold;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

