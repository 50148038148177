.section-title {
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: bold;
}

.section-description {
  font-size: 22px;
  margin-bottom: 30px;
}

.info-wrapper {
  border: 5px solid rgba(0, 0, 0, .125);
  padding: 24px;
  font-size: 16px;
}

.info-wrapper p:last-child {
  margin-bottom: 0;
}

.common-section-spacing {
  padding: 80px 0;
}

/* Services Section  */

.services {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin: 0;
  list-style: none;
  border: 1px solid #ccc;
}

.services .service {
  width: 20%;

}

.services .service a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  color: #333333;
  padding: 15px 25px;
  text-align: center;
  border: 1px solid #ccc;
  height: 100%;
}

.services .service a:hover {
  background: rgba(var(--rgb-secondary), 0.1);
}

.services .service a img {
  width: 30%;
  margin-bottom: 15px;
}

.services .service h5 {
  font-size: 14px;
}

/* News Listing  */

.news-listing {
  padding-left: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.news-listing li {
  width: 32%;
  margin-top: 29px;
  border: 1px solid #cccccc;
}

.news-listing li a {
  text-decoration: none;
  color: #333333;
}

.news-listing li:hover {
  box-shadow: 0 0 .5rem .125rem rgba(0, 0, 0, .15);
}

.news-info {
  padding: 15px;
}

.news-info .date {
  font-size: 14px;
  margin-bottom: 0;
}

.news-info h5 {
  color: var(--primary);
}

.tag-name {
  font-size: 14px;
  margin-bottom: 5px;
}

@media(max-width:990px) {
  .common-section-spacing {
    padding: 50px 0;
  }

  .services .service {
    width: 25%;
  }
}

@media(max-width:767px) {
  .services .service {
    width: 100%;
  }

  .common-section-spacing {
    padding: 40px 0;
  }

  .section-title {
    font-size: 28px;
  }

  .news-listing {
    flex-wrap: wrap;
  }

  .news-listing li {

    width: 100%;
  }
}