.news-badges {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.news-badges small {
    display: block;
    padding: 3px 5px;
    border-radius: 5px;
    background: var(--secondary);
    color: white;
    margin-right: 12px;
}

.sidebar-service-navigation {
    position: sticky;
    top: 100px;
}