footer {
    background: var(--secondary);
}

.copyright {
    background: #000000;
}

.footer-links a {
    text-decoration: none;
}

.footer-links{
    columns: 2;
    text-align: left;
}

