.news-banner {
    background-image: url('./news.png');
    background-position: center;
    background-size: cover;
    padding: 50px 0;
    color: #fff;
    position: relative;
}

.news-banner:before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.news-banner h1 {
    font-size: 50px;
    position: relative;
    z-index: 2;
}