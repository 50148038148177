.contact-banner {
  background-image: url('./contact-us.png');
  background-position: center;
  background-size: cover;
  padding: 120px 0;
  color: #fff;
  position: relative;
}

.contact-banner:before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.contact-banner h1 {
  font-size: 50px;
  position: relative;
  z-index: 2;
}

.address p {
  margin-bottom: 8px;
}

.address .labels {
  opacity: 0.8;
  font-weight: bold;
}

.text-primary {
  color: var(--primary) !important;
}

.form-control {
  font-size: 14px;
}

iframe {
  width: 100%;
}

.form-control:focus {
  box-shadow: none;
  outline: none;
  border-color: var(--primary);
}

.contact-wrapper {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}

.contact-card h5 {
  margin-bottom: 10px;
}

.contact-card:hover {
  transform: scale(1.05); 
}

.success-popup {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .contact-card {
    width: 100%;
  }
}