/*global typography*/
body {
	font-family: 'Poppins', sans-serif;
	line-height: 1.5;
	color: #333333;
	font-size: 18px;
}


@font-face {
    font-family: 'HelveticaNeue';
    src: url('../font/HelveticaNeueBold.woff2') format('woff2'),
        url('../font/HelveticaNeueBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'DJB Get Digital';
    src: url('../font/DJBGetDigital.woff2') format('woff2'),
        url('../font/DJBGetDigital.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Gothic720 BT';
    src: url('../font/Gothic720BT-RomanB.woff2') format('woff2'),
        url('../font/Gothic720BT-RomanB.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Circular Std Book';
    src: url('../font/CircularStd-Book.woff2') format('woff2'),
        url('../font/CircularStd-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



strong,
span {
	display: inline-block;
}
a {
	color: inherit;
	text-decoration: none;
	display: inline-block;
}
a:hover, a:focus {
	text-decoration: none;
	color: inherit;
}
ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
h1, h2 {
	font-family: 'Poppins', sans-serif;
}
h1 {
	font-size: 12px;
}
h2 {
	color: #202125;
	font-size: 16px;
}
h2 span{
	color: #f36e5c;
}
h3 {
	font-family: 'Poppins', sans-serif;
	color: #202125;
	font-size: 16px;
	text-transform: capitalize;
}
h4 {
	font-weight: 700;
	font-size: 18px;
}
p {
	font-size: 14px;
	color: #545454;
}
p a {
	color: #f36e5c;
}
figure {
	margin: 0;
}
img {
	max-width: 100%;
	height: auto;
}
sup {
	position: relative;
	color: #e86a59;
	font-size: 60%;
}
button:focus {
	outline: none;
}
[class^=icon-], [class*=icon-] {
	/* background-image: url(""); */
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: middle;
}
.btn {
	background-color: #fff;
	text-transform: capitalize;
	color: #023950;
	font-family: 'Poppins', sans-serif;
	border-radius: 25px;
	font-size: 12px;
	padding: 5px 10px;
}
.btn-danger {
	background-color: #f36e5c;
	color: #fff;
}
.btn-primary{
	background: #0770B4 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 10px;
	border: none;
	position: relative;
	color:#fff;
	font-size: 16px;
	font-weight: 500;
	padding: 10px 25px;
}
.btn-primary:hover{
	background-color: #174665 !important;
	color:#fff;
}
.btn-primary .icon-btn{
	height: 11px;
	width: 0;
	background-position: -237px -482px;
	transition: all 0.15s ease-in-out;
	-webkit-transition: all 0.15s ease-in-out;
	-moz-transition: all 0.15s ease-in-out;
	-ms-transition: all 0.15s ease-in-out;
}
/*primary:not(:disabled):not(.disabled):active:focus,*/
/*.btn-primary:not(:disabled):not(.disabled):active, */
/*.btn-primary:focus, .btn-primary.focus{*/
/*	box-shadow: none !important;*/
/*	-webkit-box-shadow: none !important;*/
/*	-moz-box-shadow: none !important;*/
/*	background-color: #ffe0d5;*/
/*	color:#ff946f;*/
/*}*/
primary:not(:disabled):not(.disabled):active:focus .icon-btn,
.btn-primary:not(:disabled):not(.disabled):active .icon-btn, 
.btn-primary:hover .icon-btn{
	width: 20px;
	margin-left: 5px;
}
input[type="text"], input[type="password"]{
	width: 100%;
	-webkit-appearance: none;
}
button:focus{
	outline: none;
	-webkit-appearance: none;
}
/*header style*/
.header {
	background: #F9F9FC 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #0000000F;
	border-top: 3px solid #0072BE;

}



.navbar-toggler {
	color:white;
	margin-right: 15px;
}




.navbar-toggler-icon {
	display: block;
	
	
}
.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(33, 37, 41, 0.75)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
	width: 30px;
	outline:auto ;
}

.navbar-toggler-icon:hover {
	box-shadow: black;

}

.nav-brand {
	width: 120px;
	margin: 10px 15px;
}
.navbar {
	padding: 0;
}
.right-nav {
	position: absolute;
	right: 55px;
	top: 30px;
	
}
.right-nav a {
	color: #262626;
	margin-left: 7px;
	font-weight: 500;
}
.right-nav img {
	width: 25px;
	height: 25px;
}
.search-drpdwn {
    position: relative;
}
.search-drpdwn::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 29px;
    background-color: #eaeaea;
    right: -7px;
    top: 0;
}
.navbar-nav {
	font-size: 15px;
	text-transform: capitalize;
	font-family: 'Poppins', sans-serif;
	line-height: 1.5;
}
.navbar-light .navbar-nav .nav-link {
	color: #262626;
}
.navbar-light .navbar-nav .nav-link svg {
	position: relative;
	transform: rotate(-90deg);
	width: 8px;
	margin-left: 8px;
}
.navbar-light .navbar-nav .nav-link:hover {
	color: #0A64A2;
	font-weight: 600;
}
.navbar-nav .nav-link {
	padding: 10px;
}
.navbar-collapse {
	padding: 20px 0;
}
.tel {
	font-family: 'Poppins', sans-serif;
	display: inline-block;
	background-color: #f36e5c;
	padding: 10px;
	font-size: 16px;
}
.tel i {
	margin-right: 5px;
}
.icon-tel {
	width: 16px;
	height: 16px;
	background-position: -2.5px -2.5px;
	background-size: 250px 250px;
}
.revenue {
	font-family: 'Poppins', sans-serif;
	flex-flow: wrap column;
}
.revenue li {
	font-size: 15px;
	padding: 10px;
	text-transform: capitalize;
}
.revenue li:last-child {
	font-size: 20px;
	color: #f36e5c;
}
.primary-header {
	padding: 12px 20px;
	background: #0072BE 0% 0% no-repeat padding-box;
	position: relative;
	z-index: 10;
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
}
.primary-header p {
	color: #EDEDED;
	text-transform: capitalize;
	font-size: 15px;
	margin: 0;
}
.primary-header .offer-link {
	font-weight: 500;
	color: #EDEDED;
	text-decoration: underline;
}
.primary-header svg {
	width: 16px;
	height: 15px;
	margin-left: 5px;
}
.contact-header {
	background: #F5932F 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 0px 0px 14px 14px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top:100%;
	padding:14px 32px 
}
.contact-header a {
	font-size: 16px;
	margin: 0 7px;
	color:#333333;
}
.contact-header span {
	margin-right: 7px;
}
.sub-menu {
	padding-left: 25px;
	display: none;
}
.sub-menu li {
	padding: 7px 10px;
	width: max-content;
	color: #262626;
}
.sub-menu a:hover{
	color:#0072be;
}
/*footer CSS Start*/
.primary-footer {
	padding: 30px 0;
}
.primary-footer .footer-brand {
	width: 150px;
	margin-bottom: 15px;
}
.primary-footer p {
	color: #9B9B9B;
	font-size: 13px;
	line-height: 1.5;
	font-family: 'Gothic720 BT';
}
.primary-footer h4 {
	color: #0E3855;
	font-weight: 700;
	text-transform: capitalize;
	font-size: 17px;
	margin-bottom: 15px;
}
.location-list {
	margin-bottom: 25px;
}
.location-list span{
	line-height: 1.7;
	color: #333333;
	margin-left: 5px;
	font-size: 16px;
	font-family: 'Gothic720 BT';
}
.location-list span.tag {
	background: #F5932F 0% 0% no-repeat padding-box;
	border-radius: 15px;
	padding: 0 15px
}
.location-list svg {
	width: 8px;
	position: relative;
	transform:rotate(180deg);
}
.location-list li {
	margin-bottom: 15px;
}
.social-list {
	margin-bottom: 25px;
}
.social-list li{
	display: inline-block;
	padding-right: 10px;
}
.social-list li a {
	width: 30px;
	height: 30px;
}
.footer-nav {
	margin-bottom: 25px;
}
.footer-nav a {
	color: #9B9B9B;
	font-size: 14px;
	line-height: 1.5;
	font-family: 'Gothic720 BT';
}
.footer-nav li{
	padding: 5px 0;
}
.secondry-footer {
	padding: 20px 0;
	background: #363636 0% 0% no-repeat padding-box;
}
.secondry-footer .secondry-links{
	color: #CCCCCC !important;
	line-height: 1.5 !important;
	font-size: 13px !important;
	font-family: 'Gothic720 BT' !important;
	
}
.secondry-footer p{
	color: #CCCCCC;
	line-height: 1.5;
	font-size: 13px;
	font-family: 'Gothic720 BT';
}
.secondry-links a {
	color: #CCCCCC;
	line-height: 1.5;
	font-size: 13px;
	margin-right: 40px;
	font-family: 'Gothic720 BT';
}
.secondry-links a:last-child {
    margin-right: 0px;
}
.secondry-links a:hover {
	text-decoration: underline;
}
.search-form,
.login-dropdown {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 4px 46px #00000041;
	border-radius: 4px;
	position: absolute;
	right: 0;
	top: calc(100% + 20px);
	padding: 15px 15px;
	width: 200px;
	z-index: 9;
	display: none;
}
.search-form:after,
.login-dropdown:after{
	width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  content:"";
  border-bottom: 8px solid white;
  position: absolute;
  top: -8px;
  right: 20px;
}
.login-dropdown a {
	position: relative;
	margin: 0;
	color:#262626;
	font-weight: 500;
	padding-right: 30px;
	display: block;
	font-size: 14px;
}
.login-dropdown li{
	padding: 10px 0;
}
.login-dropdown a i {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
}
.login-dropdown a svg {
	width: 8px;
}
.search-form {
	right: 15px;
}
.search-form .search-icn {
	position: absolute;
	right: 0;
	border: 0;
	background: none;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
}
.search-form:after {
	left: 25px;
	right: auto;
}
.search-form .form-group {
	margin: 0;
	position: relative;
}

footer p, footer h2{
	color:white
}

