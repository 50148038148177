/* home.css */

.home-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; 
    margin-bottom: 30px;
  }


  
  .service-card {
    text-align: center;
    margin: 5px;
    
  }
  .service-card:hover {
    transform: scale(1.05); 
  }
  
  .circle-border {
    border: 1px solid #c0c0c0; 
    border-radius: 50%;
    width: 220px; 
    height: 220px; 
    overflow: hidden;
  }
  
  .circle-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    
  }
  
  .circle-logo {
    width: 50%; 
    height: auto;
   
  }
  
  .service-name {
    margin-top: 5px; 
    font-size: 14px; 
    text-decoration: none;
  }
  
  .service-name:hover,
.service-name:active,
.service-name:visited {
  text-decoration: none;
}

.services-section-title{
  text-align: center;
  font-size: 30px;
  margin-bottom: 15px;
}
