body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
  padding-top: 75px;
}


:root {
  --primary: #f69052;
  --secondary: #0869a8;
  --rgb-secondary: 8, 105, 168;
 
}

.btn-outline-primary {
  --bs-btn-color: #f69052;
  --bs-btn-border-color: #f69052;
  --bs-btn-hover-bg: #f69052;
  --bs-btn-hover-border-color: #f69052;
  --bs-btn-active-bg: #f69052;
  --bs-btn-active-border-color: #f69052;
  --bs-btn-disabled-color: #f69052;
  --bs-btn-disabled-border-color: #f69052;
}

.btn-primary {
  --bs-btn-bg: #f69052;
  --bs-btn-border-color: #f69052;
  --bs-btn-hover-bg: #f69052;
  --bs-btn-hover-border-color: #f69052;
  --bs-btn-active-bg: #f69052;
  --bs-btn-active-border-color: #f69052;
  --bs-btn-disabled-bg: #f69052;
  --bs-btn-disabled-border-color: #f69052;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/font/HelveticaNeueBold.woff2') format('woff2'),
      url('./assets/font/HelveticaNeueBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'DJB Get Digital';
  src: url('./assets/font/DJBGetDigital.woff2') format('woff2'),
      url('./assets/font/DJBGetDigital.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gothic720 BT';
  src: url('./assets/font/Gothic720BT-RomanB.woff2') format('woff2'),
      url('./assets/font/Gothic720BT-RomanB.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Circular Std Book';
  src: url('./assets/font/CircularStd-Book.woff2') format('woff2'),
      url('./assets/font/CircularStd-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}