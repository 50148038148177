.about-banner {
    background-image: url('./about-us.jpg');
    background-position: center;
    background-size: cover;
    padding: 120px 0;
    color: #fff;
    position: relative;
}

.about-description {
    font-size: 18px;
    text-align: justify;
}

.quotes-wrapper {
    padding: 50px 0;
    background-color: #ebebeb;
}

quote {
    background: whitesmoke;
    padding: 80px 32px;
    display: block;
    position: relative;
}

quote::before {
    position: absolute;
    content: '“';
    font-size: 60px;
    color: gray;
    top: 0;
    left: 16px;
}

quote::after {
    position: absolute;
    content: '”';
    font-size: 60px;
    color: gray;
    bottom: -10px;
    right: 16px;
}

.timeline-wrapper {
    padding: 50px 0;
}

.timeline {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4em;
    z-index: 0;
    justify-content: space-evenly;
    justify-items: center;
    align-content: center;
    align-items: center;
    text-align: center;
    position: relative;
}

.card-item-pcblue {
    color: #ffffff;
    position: relative;
    width: 100%;
}


.timeline::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 78%;
    background-color: #d5d5d5;
    top: 20px;
    z-index: -1;
}

.subcard {
    padding: 1.5em;
    background: #fdfdfd;
    color: black;
    box-shadow: 4px 4px 8px #d9d9d9fc;
    margin: 1em 1.5rem 0;
    width: -webkit-fill-available;
}

.circle-custom {
    display: inline-block;
    height: 2.5rem;
    line-height: 2.5rem;
    position: relative;
    vertical-align: middle;
    width: 2.5em;
    border-radius: 100%;
    background: var(--primary);
}

.section-paddding {
    padding: 1em 2em;
}

.section-margin {
    margin: 1em 2em;
}

header {
    margin-bottom: 4rem;
}

@media (max-width: 650px) {
    .timeline {
        display: block !important;
    }

    .subcard {
        display: block;
    }

    .timeline::after {
        display: none !important;
    }

    .card-item-pcblue {
        margin-bottom: 40px;
    }

    .about-description {
        font-size: 15px;
    }
}