.service-banner {
    background-position: center;
    background-size: cover;
    padding: 120px 0;
    color: #fff;
    position: relative;
}

.service-banner:before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.service-banner h1 {
    font-size: 50px;
    position: relative;
    z-index: 2;
}

.sidebar-service-navigation {
    padding: 15px 0 0;
    border: 1px solid #cccccc;
}

.sidebar-services {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.sidebar-service-navigation h3 {
    padding: 0 15px;
}

.sidebar-services li a {
    color: #333333;
    text-decoration: none;
    padding: 10px 15px;
    display: block;
}

.sidebar-services li a:hover,
.sidebar-services li a.active {
    background-color: var(--primary);
    color: #ffffff;
}

.description {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 500;
}

.content {
    font-size: 18px;
}

.content h3 {
    margin-bottom: 20px;
}

.content h4 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.5;
}

.content li {
    margin: 12px 0;
}