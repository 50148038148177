.logo {
    max-width: 200px;
}

.navbar {
    padding: 8px 15px;
    border-bottom: 1px solid gray;
}

.navbar-nav .nav-link {
    font-weight: 500;
    margin: 0 15px;
}

.navbar-nav .dropdown-menu {
    left: auto;
    right: 0;
    border-radius: 0;
    min-width: 200px;
    margin-top: 15px;
}

.navbar-nav .is-active {
    color: var(--primary);
}